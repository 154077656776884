import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logo from "./Logo";

const Nav = () => {

  return (
    <StyledNav>
      <Logo />
    </StyledNav>
  );
};

export default Nav;

const StyledNav = styled.nav`
  position: relative;
  /*
  display: flex;
  flex-direction: column;
  justify-content: center;
  */
  padding: 1.5em var(--gutter);
  @media ( min-width: 48em ) {
    //padding: 1.5em 2em;
  }
  @media ( min-width: 60em ) {
    padding-top: 6em;
    padding-bottom: 0;
  }
`;
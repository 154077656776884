import React, { useEffect } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import "../fonts/fonts.css";
import Footer from "./Footer";
import "./layout.scss";
import Nav from "./Nav";

const registerTouchables = () => {
  const touchables = document.querySelectorAll('button, button, a[href], label');
  let touchablesLength = touchables.length;


  while ( touchablesLength-- ) {
    touchables[touchablesLength].addEventListener('touchstart', () => {}, {passive: true});
    touchables[touchablesLength].addEventListener('touchend', () => {}, {passive: true});
  }
}
const Layout = props => {

  useEffect(() => {

    document.getElementsByTagName( 'html' )[0].classList.add(`js`);

    registerTouchables()

    const canHover = !(matchMedia(`(hover: none)`).matches)
    if ( canHover ) {
      document.getElementsByTagName( 'html' )[0].classList.add(`can-hover`);
    }

    const loading = document.querySelector('.is-loading');

    if ( loading ) {
      setTimeout(() => {
        loading.classList.remove('is-loading')
        loading.classList.add('loaded')
      },2000)
    }
  });

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
      </Helmet>
      <StyledSite className="is-loading">
        <Nav />
        <StyledSiteContent id="content">
          {props.children}
        </StyledSiteContent>
        <Footer />
      </StyledSite>
    </>
  );
};

export default Layout;

const StyledSite = styled.div`
  position: relative;
`;
const StyledSiteContent = styled.main`
  position: relative;
  width: 100%;
  padding: 0 var(--gutter);
`;
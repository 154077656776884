import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { useStaticQuery, graphql } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const Footer = () => {

  const data = useStaticQuery(graphql`
    query FooterQuery {
      directus {
        mural_project {
          footer_credits
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <Logo />
      <div><ReactMarkdown>{data.directus.mural_project.footer_credits}</ReactMarkdown></div>
    </StyledFooter>
  )
};

export default Footer;

const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5em var(--gutter);
  padding-bottom: 6em;
  > div:last-of-type {
    text-align: right;
    font-weight: var(--text-weight);
    margin-top: 2em;
    a {
      text-decoration-line: underline;
      text-decoration-thickness: 0.75px;
      text-underline-offset: 0.1em;
      text-decoration-color: var(--theme-heavy-border-against-dark);
      transition: opacity 0.2s ease;
    }
    .can-hover & a:hover,
    a:active {
      opacity: 0.5;
    }
  }
  @media ( min-width: 60em ) {
    padding-top: 6em;
  }
`;
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Logo = () => {
  return (
    <StyledLogoWrap>
      <StyledLogo 
          as={Link}
          to="/" 
          title="The Outlaw Ocean Mural Project"
        >
          <span><span>The Outlaw Ocean</span></span> <span><span>Mural Project</span></span>
      </StyledLogo>
    </StyledLogoWrap>
  );
};

export default Logo;

const StyledLogoWrap = styled.div`
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    display: block;
    font-size: 8vw;
    bottom: 4vw;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    height: 0.17em;
    background-color: currentColor;
    @media ( min-width: 48em ) {
      font-size: 3.5em;
      bottom: 0.4em;
    }
  }
`

const StyledLogo = styled.a`
  display: inline-block;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -0.075em;
  font-size: 9vw;
  .can-hover &:hover,
  &:active {
    color: #8c8c8c;
  }
  > span {
    position: relative;
    display: block;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    > span {
      position: relative;
      display: block;
      background-color: var(--color-background);
      padding: 0 0.125em;
    }
  }
  em {
    font-style: normal;
    color: #fff;
  }
  /*
  em::before {
    content: "(";
    margin-right: 0.1em;
    display: inline-block;
    opacity: 0.5;
    position: relative;
    transform: translate3d(0,-0.1em,0);
  }
  em::after {
    content: ")";
    margin-left: 0.1em;
    display: inline-block;
    opacity: 0.5;
    position: relative;
    transform: translate3d(0,-0.1em,0);
  }
  */
  
  
  > span:first-of-type > span {
    margin-left: -0.125em;
  }
  
  > span:last-of-type {
    justify-content: flex-end;
    > span {
      margin-right: -0.125em;
    }
  }

  @media ( max-width: 48em ) {
    > span:first-of-type {
      margin-left: -0.05em;
    }
  }
  @media ( min-width: 48em ) {
    line-height: 0.9;
    letter-spacing: -0.0125em;
    letter-spacing: -0.05em;
    font-size: 3.5em;
    max-width: 9.9375em;
  }
`;